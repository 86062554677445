// import type
import { DISPLAY_TOAST } from "./type";

export const toast = (data, dispatch) => {
  dispatch({
    type: DISPLAY_TOAST,
    toast: {
      message: data.message,
      type: data.type,
    },
  });
};