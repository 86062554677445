import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// import style
import "./scss/style.scss";

// import component
import ToastCAlert from "./components/ToastCAlert";

const Landing = React.lazy(() => import("./views/Landing/Landing"));
const FerzanToken = React.lazy(() => import("./views/FerzanToken/FerzanToken"));
const FerzanWalletToken = React.lazy(() => import("./views/FerzanWalletToken/FerzanWalletToken"));
const PrivacyPolicy = React.lazy(() => import("./views/PrivacyPolicy/PrivacyPolicy"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  return (
    <BrowserRouter basename="/">
      <Suspense fallback={loading}>
        <ToastCAlert />
        <Routes>
          <Route exact path="/" name="Landing Page" element={<Landing />} />
          <Route exact path="/ferzan-token" name="Landing Page" element={<FerzanToken />} />
          <Route exact path="/ferzan-wallet-token" name="Landing Page" element={<FerzanWalletToken />} />
          <Route exact path="/privacy" name="Privacy Policy" element={<PrivacyPolicy />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;